import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import LineSeparator from "../../components/LineSeparator";
import LoadingSpinner from "../../components/LoadingSpinner";
import PageBanner from "../../components/PageBanner";
import Layouts from "../../layouts/Layouts";
import useApprovedOffers from "../../utils/useApprovedOffers";
import "./style.css";

export default function OffersPage() {
  const { t, i18n } = useTranslation();
  const language = i18n.language.toUpperCase();
  const { offers, loading } = useApprovedOffers(language);
  const text = "text" + language;
  const title = "title" + language;

  return (
    <Layouts>
      <PageBanner pageName={t("offers")} />
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="offers-container">
          {offers && offers.length ? (
            offers.map((offer, index) => {
              return offer[text] !== "" && offer[title] !== "" ? (
                <Fragment key={index}>
                  <div className="single-offer">
                    <img src={offer.image} alt="" />
                    <div className="offer-content">
                      <div className="offer-title">{offer[title]}</div>
                      <div dangerouslySetInnerHTML={{ __html: offer[text] }} />
                    </div>
                  </div>
                  {index !== offers.length - 1 && <LineSeparator />}
                </Fragment>
              ) : (
                <></>
              );
            })
          ) : (
            <div
              className="no-available-offers"
              style={{ alignSelf: "center" }}
            >
              {t("no-offers")}
            </div>
          )}
        </div>
      )}
    </Layouts>
  );
}

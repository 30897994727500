import React, { useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import DatePicker from "./DatePicker";
import { getDateDB, getTomorrowDate } from "../utils/date";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import availabilityService from "../services/availabilityService";
import Select from "react-select";
import Context from "../utils/context";

const options = [
  { value: 0, label: "0" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
  { value: 13, label: "13" },
  { value: 14, label: "14" },
  { value: 15, label: "15" },
];

export default function AvailabilityPicker({
  setBookingData,
  setRoomsToDisplay,
  setIsRoomsLoading,
  setShowBookingView,
}) {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const { availabilityData: data, setAvailabilityData: setData } =
    useContext(Context);

  useEffect(() => {
    const current = new Date().toLocaleDateString();
    const tomorrow = getTomorrowDate().toLocaleDateString();

    if (
      current !== data.startDate?.toLocaleDateString() &&
      tomorrow !== data.endDate?.toLocaleDateString()
    ) {
      onSubmit({ preventDefault: () => {} });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const [data, setData] = useState({
  //   startDate: new Date(),
  //   endDate: getTomorrowDate(),
  //   adults: { value: 1, label: "1" },
  //   children: { value: 0, label: "0" },
  // });

  const childrenOptions = options.map((x) => {
    return {
      value: x.value,
      label: `${x.label} ${x.value > 0 ? t("year-cap") : ""}`,
    };
  });

  const adultsOptions = options.filter((x) => x.value !== 0);

  const getAvailability = async (data) => {
    let dataCopy = { ...data };
    dataCopy["children"] = data.children.value;
    dataCopy["adults"] = data.adults.value;
    // dataCopy["rooms"] = data.rooms.value;
    dataCopy["startDate"] = getDateDB(data.startDate);
    dataCopy["endDate"] = getDateDB(data.endDate);
    const res = await availabilityService.getAvailabilityForPeriod(dataCopy);
    setShowBookingView(true);
    setRoomsToDisplay(res.data);
    setIsRoomsLoading(false);
  };

  useEffect(() => {
    if (location.state) {
      getAvailability(location.state.data);
      setData(location.state.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleChange = (field, value) => {
    let dataCopy = { ...data };
    dataCopy[field] = value;
    setData({ ...dataCopy });
    if (field === "adults" || field === "children") {
      setBookingData({ ...dataCopy });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const currentLocation = history.location.pathname;

    if (data.adults.value === 0) {
      toast.error(t("choose-adults"));
      return;
    }
    const startDate = new Date(data.startDate.setHours(0, 0, 0, 0)).getTime();
    const endDate = new Date(data.endDate.setHours(0, 0, 0, 0)).getTime();

    if (startDate >= endDate) {
      console.log("error");
      toast.error(t("invalid-period"));
      return;
    }
    if (currentLocation === "/home" || currentLocation === "/") {
      console.log("home");
      history.push({
        pathname: "/rooms",
        state: {
          data,
        },
      });
    } else {
      setIsRoomsLoading(true);
      await getAvailability(data);
      setIsRoomsLoading(false);
      setBookingData(data);
    }
  };
  return (
    <div className="check-availability">
      <div className="auto-container">
        <form className="form" onSubmit={(e) => e.preventDefault()}>
          <div className="left-side">
            <ul>
              <li>
                <DatePicker
                  date={data.startDate}
                  onChange={(date) => handleChange("startDate", date)}
                  placeholder={`Arrival Date`}
                />
              </li>
              <li>
                <DatePicker
                  date={data.endDate}
                  onChange={(date) => handleChange("endDate", date)}
                  placeholder={`Departure Date`}
                />
              </li>
              <li style={{ display: "flex", alignItems: "center" }}>
                <label htmlFor="adults-select">{t("adults")}</label>
                <Select
                  openMenuOnFocus={true}
                  inputId="adults-select"
                  options={adultsOptions}
                  value={
                    data.adults ? data.adults : { label: t("adults"), value: 0 }
                  }
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(value) => handleChange("adults", value)}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      border: 0,
                      boxShadow: "none",
                    }),
                  }}
                />
              </li>
              <li style={{ display: "flex", alignItems: "center" }}>
                <label htmlFor="children-select" id="children-select-label">
                  {t("children")}
                </label>
                <Select
                  openMenuOnFocus={true}
                  inputId="children-select"
                  options={childrenOptions}
                  value={
                    data.children ? data.children : { label: "0", value: 0 }
                  }
                  onChange={(value) => handleChange("children", value)}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  menuPortalTarget={document.body}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      border: 0,
                      boxShadow: "none",
                    }),
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 999,
                      width: "150px",
                    }),
                  }}
                />
              </li>
            </ul>
          </div>
          <div className="right-side">
            <button onClick={onSubmit} type="submit">
              {t("check-availability")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
